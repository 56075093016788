<template>
  <div class="flex flex-row flex-grow md:flex-none items-center space-x-1">
    <Icon name="carbon:network-4-reference" class="text-4xl" />
    <h1 class="font-extrabold text-2xl">AutoNetCancer</h1>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
